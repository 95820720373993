<template>
  <div :style="{ height: height, width: width }" class="bg" ref="chart"></div>
</template>
<script>
import { debounce } from "lodash";

const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        seriesData: [],
      },
    },
    titleName: {
      type: String,
      default: "占比",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      colors: [
        "#76fdfc",
        "#51f1fe",
        "#4effe8",
        "#01ffb3",
        "#00d489",
        "#d8ff85",
        "#eeeb47",
        "#ffd645",
        "#ff3567",
        "#e759b1",
        "#b95af2",
        "#6f5af2",
        "#0073ff",
        "#3398ff",
        "#05b5ff",
        "#70ddfe",
        "#76e8fd",
      ], //['#00ff5a', '#26fff7']
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        console.log(val,'----')
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    // let _this = this;
    // this.chart.on('click', function (params) {
    //   if(params.data.type == "supervise"){
    //     _this.$emit("getClickData",params.name)
    //   }else if(params.data.type == "place"){
    //     _this.$emit("getClickData",params.name)
    //   }
    // });
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      var rich = {
        value: {
          fontSize: 20,
          align: "left",
        },
        name: {
          align: "left",
          fontSize: 16,
          padding: [10, 0],
          color: "#656565",
        },
        value2: {
          align: "left",
          fontSize: 16,
          padding: [10, 0],
          color: "#fff",
        },
        unit: {
          fontSize: 16,
          align: "left",
        },
      };
      this.chart.setOption({
        title: {
          text: _this.titleName,
          x: "center",
          y: "center",
          itemGap: 20,
          textStyle: {
            color: "#1182ea",
            fontFamily: "微软雅黑",
            fontSize: 16,
          },
        },
        series: [
          {
            startAngle: 100,
            type: "pie",
            radius: ["65%", "85%"],
            minAngle: 20,
            silent: true,
            // emphasis: {
            //   normal: {
            //     show: false,
            //   },
            // },
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#fff",
                borderWidth: 2,
                color: function (params) {
                  return _this.colors[params.dataIndex];
                },
              },
            },

            data: _this.chartData.seriesData,
          },
        ],
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
