<template>
  <div :style="{height:height,width:width}" class="bg" ref="chart"></div>
</template>
<script>
import {debounce} from 'lodash'
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
        type:Array,
        default:[{}]
    },
    titleName: {
        type: String,
        default: '占比'
      },
    width: {
        type: String,
        default: '100%'
      },
      height: {
        type: String,
        default: '100%'
      },
      colors:{
        type:Array,
        default:['#208dfd','rgba(102,163,215,0.5)']
      }
  },
  data() {
    return {
      //colors: ['#208dfd','rgba(102,163,215,0.5)'],//['#00ff5a', '#26fff7']
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true
    }
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    let _this = this;
    this.chart.on('click', function (params) {
      if(params.data.type == "supervise"){
        _this.$emit("getClickData",params.name)
      }else if(params.data.type == "place"){
        _this.$emit("getClickData",params.name)
      }
    });
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);

      var rich = {
          value: {
              fontSize: 42,
              align: 'left'
          },
          name: {
              align: 'left',
              fontSize: 24,
              padding:[10,0]
          },
          unit:{
            fontSize:20,
            align: 'left'
          }
      }
      this.chart.setOption( {
        title: {
            text: _this.titleName+'%',
            x: 'center',
            y: 'center',
            itemGap: 20,
            textStyle : {
                color : '#232a24',
                fontFamily : '微软雅黑',
                fontSize : 12,
                fontWeight : 'normal'
            }
        },
        series: [{
            type: 'pie',
            radius: ['43%', '55%'],
            hoverAnimation: false,
            label: {
                normal: {
                  align: "left",
                  //formatter:'{c|blue}\n{d}%\n{b}',
                  formatter: function(params, ticket, callback) {
                      var percent = (params.percent).toFixed(2);
                      return '{value|' + params.value + '}{unit|'+ params.data.unit +'}\n{value|' + percent + '%}\n{name|' + params.name + '}';
                  },
                  rich: rich
                },
            },
            labelLine: {
                normal: {
                  show:false,
                    length:80,
                    length2: 30,
                    lineStyle: {
                        type:'dashed'
                    }
                }
            },
            itemStyle:{
                normal:{
                    // borderColor:'#216ba1',
                    // borderWidth:2,
                    color:function(params){
                      return _this.colors[params.dataIndex];
                    }
                }
            },
            data:_this.chartData
        }]
      });
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
