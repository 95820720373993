<template>
  <div class="public">
    <div class="container homeConsumptionstructure">
      <!-- 四色码 -->
      <Toptab :topdata="forCodeData" :data="dataregion" type="scroll2" />
      <!-- 消费区域分布情况 地图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 消费人群新增变化趋势 柱状图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管新增变化趋势</span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal2"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
              v-loading="barChart1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="barChartData"
              :legendData="[
              '应急处理',
              '执法检查',
              '投诉处理',
              '依法处置',
              '责令整改',
              '纠纷处理',
              '其他',
            ]"
            :units="['件', '件', '件', '件', '件', '件', '件']"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
      </div>
      <!-- 消费人群累计变化趋势 柱图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管累计变化趋势</span>
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal4"></KcSelect>
        </div>
        <div class="charts">
          <!-- <BarChart
            :chartData="barChartDatatwo"
            :legendData="['儿童', '少年', '青年', '中年', '老年']"
          ></BarChart> -->
          <LineChart
              v-loading="barChart2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              :chartData="barChartDatatwo"
              :legendData="[
              '应急处理',
              '执法检查',
              '投诉处理',
              '依法处置',
              '责令整改',
              '纠纷处理',
              '其他',
            ]"
            :units="['件', '件', '件', '件', '件', '件', '件']"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
      </div>
      <!-- 消费人群按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>综合监管区域分布数量统计</span>
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="options"
            @popupVal="popupVal5"
          ></KcSelect>
        </div>

        <div class="element-tab">
          <el-table
              :data="tableDataAll"
              v-loading="loading1"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              :row-key="rowKey"
              lazy
              :load="loadNextNode1"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort"
              :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
                prop="regionName"
                :key="0"
                label="地区"
                :width="0.18 * $bodyWidth"
                fixed
            >
            </el-table-column>
            <el-table-column prop="num" label="应急处理" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum1 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="执法检查" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum2 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="投诉处理" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum3 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="依法处置" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum4 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="责令整改" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum5 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="纠纷处理" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum6 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="其他" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.supervisionNum7 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>

          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
            >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
      <!-- 消费人群按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>预付码领域/行业分布数量统计</span>
          </div>
          <KcSelect
              isBlue
              keyLabel="haripinType"
              :optionData="options"
              right="0"
              top="-35"
              @popupVal="popupVal6"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
              :data="tableDataAll2"
              v-loading="loading2"
              element-loading-text="加载中..."
              element-loading-spinner="el-icon-loading"
              style="width: 100%"
              row-key="industryName"
              lazy
              :load="loadNextNode2"
              :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
              @sort-change="changeSort2"
              :row-style="showRow"
              :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
                prop="industryName"
                :key="0"
                label="领域/行业"
                sortable
                :width="0.32 * $bodyWidth"
                marginBottom="5"
                fixed
            >
            </el-table-column>
            <el-table-column prop="num" label="应急处理" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num1 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greyNum" label="执法检查" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num2 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="redNum" label="投诉处理" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num3 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="yellowNum" label="依法处置" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num4 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="greenNum" label="责令整改" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num5 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="纠纷处理" sortable :width="0.25 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num6 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>
            <el-table-column prop="blackNum" label="其他" sortable :width="0.21 * $bodyWidth">
              <template slot-scope="scope">
                <span class="num-box">{{ scope.row.num7 }}</span
                ><i
                  :class="
                    0 > 0 ? 'num-badge-blue' : 0 < 0 ? 'num-badge-orag' : 'none'
                  "
              >{{ 0 > 0 ? "+" + 0 : 0 < 0 ? "" + 0 : "" }}</i
              >
              </template>
            </el-table-column>

          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
            >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                  class="el-icon-d-arrow-left el-icon--right"
                  :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
              ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PieSupervise"; //饼图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import Baidumap from "@/components/baidumap/index.vue";
import BarChart from "@/components/charts/bar";
import PieEpide from "@/components/charts/pieHomeEpide";
import Tab from "@/components/tab.vue";
import Toptab from "@/components/toptab.vue";
import {
  compositeSuperviseStatistics,
  compositeSuperviseDistribution,
  compositeSuperviseAdd,
  compositeSuperviseCumulative,
  compositeSuperviseCityStatistics,
  compositeSuperviseIndustry,
} from "@/api/homeSynthesizeSupervise.js";
import { cardType } from "@/api/common";

import Pmap from "@/components/charts/map.vue"; //地图

export default {
  name: "homeConsumptionstructure",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      barChart1: false,
      barChart2: false,
      optionData: [
        { name: "消费人次", haripinType: 1 },
        { name: "预付卡交易金额", haripinType: 2 },
      ],
      outing: [],
      options: [],
      forCodeData: [
        {
          name: "应急处理",
          val: 0,
          com: "次",
        },
        {
          name: "纠纷处理",
          val: 0,
          com: "次",
        },
        {
          name: "投诉处理",
          val: 0,
          com: "次",
        },
        {
          name: "责令整改",
          val: 0,
          com: "次",
        },
        {
          name: "执法检查",
          val: 0,
          com: "次",
        },
        {
          name: "依法处置",
          val: 0,
          com: "次",
        },
        {
          name: "其他",
          val: 0,
          com: "次",
        }
      ],
      dataregion:[
        {
          name: "较昨日",
          data: [0,0,0,0,0,0,0],
        },
        {
          name: "较上周",
          data: [0,0,0,0,0,0,0],
        },
        {
          name: "较上月",
          data: [0,0,0,0,0,0,0],
        },
        {
          name: "较上年",
          data: [0,0,0,0,0,0,0],
        },
      ],
      forCodeDatacolor: ["#125fe0", "#208dfd", "#18bbff", "#00ff5a", "#c6ff00"],
      typeName2: "资金",
      typeName3: "资金",
      typeName4: "资金",
      typeName5: "资金",
      tabPosition1: 1,
      tabPosition3: 1,
      tabPosition4: "",
      tabPosition5: "",
      isTowPie: false,
      statisticsData: [],
      cunguan: "",
      baoxian: "",
      units1: ["", "", "", "", ""],
      units2: ["", "", "", "", ""],
      pieChartData: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [],
      },
      barChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "儿童",
            data: [],
          },
          {
            name: "少年",
            data: [],
          },
          {
            name: "青年",
            data: [],
          },
          {
            name: "中年",
            data: [],
          },
          {
            name: "老年",
            data: [],
          },
        ],
      },
      barChartDatatwo: {
        xAxisData: [],
        seriesData: [
          {
            name: "儿童",
            data: [],
          },
          {
            name: "少年",
            data: [],
          },
          {
            name: "青年",
            data: [],
          },
          {
            name: "中年",
            data: [],
          },
          {
            name: "老年",
            data: [],
          },
        ],
      },
      legendData: ["存管商户发卡金额", "保险商户发卡金额"],
      unitData: ["元", "元"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            data: [],
          },
          {
            name: "保险商户发卡金额",
            data: [],
          },
        ],
      },
      legendData2: ["存管商户发卡金额", "保险商户发卡金额"],
      unitData2: ["元", "元"],
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      showAll3: false,
      tableDataAll3: [],
      tableData3: [],
      pieSmallChartData: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      pieSmallChartData2: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    tabPosition1: function (val) {
      //this.isTowPie = false
      this.getInsuranceBankStatistics();
      if (val == 1) {
        this.moneyStatistics();
      } else {
        this.busStatistics();
      }
    },
    radio1: function (val) {
      this.compositeSuperviseAdd();
    },
    radio2: function (val) {
      this.compositeSuperviseCumulative();
    },
    tabPosition4: function (val) {
      this.compositeSuperviseCityStatistics();
    },
    tabPosition5: function (val) {
      this.compositeSuperviseIndustry();
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    showRow({ row, rowIndwx }) {
      // console.log(row, rowIndwx);
      let styleJson = {
        marginBottom: 5,
        borderRadius: 5,
      };

      // if (row.show) {
      //   styleJson = {
      //     display: "block",
      //   };
      // } else {
      //   styleJson = {};
      // }
      return styleJson; // 返回对象
    },
    popupVal2(val) {
      this.radio1 = val;
      // console.log(val);
      // console.log(row);
    },

    popupVal4(val) {
      this.radio2 = val;
    },
    popupVal5(val) {
      this.tabPosition4 = val;
    },
    popupVal6(val) {
      this.tabPosition5 = val;
    },

    intData() {
      this.cardType();
      this.compositeSuperviseStatistics();
      this.compositeSuperviseDistribution();
      this.compositeSuperviseAdd();
      this.compositeSuperviseCumulative();
      this.compositeSuperviseCityStatistics();
      this.compositeSuperviseIndustry();
    },
    compositeSuperviseStatistics() {
      compositeSuperviseStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          if (res.data != "") {
            res.data.map((val, ind) => {
              this.forCodeData[ind].val = val.supervisionNum;
              this.forCodeData.push(1);
              this.forCodeData.pop();
              this.dataregion[0].data.map((v, i) => {
                return val.yesterdayNum;
              });
              this.dataregion[1].data.map((v, i) => {
                return val.weekNum;
              });
              this.dataregion[2].data.map((v, i) => {
                return val.monthNum;
              });
              this.dataregion[3].data.map((v, i) => {
                return val.yearNum;
              });
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    compositeSuperviseDistribution() {
      this.showbmap = true;
      this.showmap = true;
      compositeSuperviseDistribution({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: 0,
              data: {
                warning: item.supervisionNum1,
                fine: item.supervisionNum2,
                illegalincome: item.supervisionNum3,
                production: item.supervisionNum4,
                license: item.supervisionNum5,
                detention: item.supervisionNum6,
                other: item.supervisionNum7,
              },
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },


    compositeSuperviseAdd() {
      this.barChart1 = true;
      compositeSuperviseAdd({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
      })
          .then((res) => {
            this.barChart1 = false;
            this.barChartData = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "应急处理",
                  data: res.data.supervisionNum1List,
                },
                {
                  name: "纠纷处理",
                  data: res.data.supervisionNum2List,
                },
                {
                  name: "投诉处理",
                  data: res.data.supervisionNum3List,
                },
                {
                  name: "责令整改",
                  data: res.data.supervisionNum4List,
                },
                {
                  name: "执法检查",
                  data: res.data.supervisionNum5List,
                },
                {
                  name: "依法处置",
                  data: res.data.supervisionNum6List,
                },
                {
                  name: "其他",
                  data: res.data.supervisionNum7List,
                },

              ],
            };
          })
          .catch((err) => {
            console.log(err);
          });
    },
    compositeSuperviseCumulative() {
      this.barChart2 = true;
      compositeSuperviseCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
      })
          .then((res) => {
            this.barChart2 = false;
            this.barChartDatatwo = {
              xAxisData: res.data.x_axis,
              seriesData: [
                {
                  name: "应急处理",
                  data: res.data.supervisionNum1List,
                },
                {
                  name: "纠纷处理",
                  data: res.data.supervisionNum2List,
                },
                {
                  name: "投诉处理",
                  data: res.data.supervisionNum3List,
                },
                {
                  name: "责令整改",
                  data: res.data.supervisionNum4List,
                },
                {
                  name: "执法检查",
                  data: res.data.supervisionNum5List,
                },
                {
                  name: "依法处置",
                  data: res.data.supervisionNum6List,
                },
                {
                  name: "其他",
                  data: res.data.supervisionNum7List,
                },

              ],
            };
          })
          .catch((err) => {
            console.log(err);
          });
    },
    cardType() {
      cardType().then((res) => {
        res.data.unshift({ name: "全部", haripinType: "" });
        this.options = res.data;
      });
    },
    compositeSuperviseCityStatistics() {
      this.loading1 = true;
      compositeSuperviseCityStatistics({
        hairpinType: this.tabPosition4,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        // console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.tableDataAll = [];
        this.loading1 = false;
        this.$nextTick(() => {
          this.tableData1 = res.data || [];
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },

    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      consumerAmountCityStatistics({
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
        hairpinType: this.tabPosition4,
      }).then((res) => {
        //console.log('预付码按城市/地区统计-二级',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data);
      });
    },
    compositeSuperviseIndustry() {
      this.loading2 = true;
      compositeSuperviseIndustry({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        hairpinType: this.tabPosition5,
      }).then((res) => {
        //console.log('预付码按领域/行业统计',res)
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data;
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      compositeSuperviseIndustry({
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
        hairpinType: this.tabPosition5,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    PieChart,
    BarChart,
    PieSmallChart,
    PieEpide,
    Pmap,
    Tab,
    Baidumap,
    Toptab,
  },
};
</script>

<style lang="scss" scoped >
.homeConsumptionstructure {
  .pie-box {
    margin-bottom: 30px;
    // background: url("../assets/imgs/pie-bg3.png") no-repeat;
    // background-size: 100% 100%;
    // margin-bottom: 0;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 550px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;
        &:nth-child(3) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            30 30;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
}
</style>
